

const NotesErrorMsg = () => {

  return (
    <article className="notes-error-msg-container">
      <p>Please fill in both fields.</p>
    </article>
  );

}

export default NotesErrorMsg
