
import Navbar from "../../Navbar/Navbar";


const ShipsGame = () => {

  return (
    <>
      <Navbar />
  
        <article id="ships-game-container">
          <h1> WORK IN PROGRESS</h1>
        </article>
   
    </>
  );
};

export default ShipsGame;
